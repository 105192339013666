
// import { mapGetters } from 'vuex';
import { debounce } from 'lodash';

export const storeRecListMixin = {
  data() {
    return {
      rec: null,
      recId: this.$route.params.recId,
      qry: {
        field: null,
        val: null,
        terms: [],
      },
      // vuexPath: '',
      // vuexGetRecordAction: null,
    };
  },
  computed: {
    hasPageSet() {
      return this.$store.getters[this.vuexPath + '/hasPageSet'];
    },

    // state
    page_size() {
      let key =  this.vuexPath;
      let fields = key.split('/');
      fields.push('page_size');

      return fields.reduce( (a,b) => a[b], this.$store.state);
    },

    numRecs() {
      let key =  this.vuexPath;
      let fields = key.split('/');
      fields.push('qry_total');

      return fields.reduce( (a,b) => a != undefined ? a[b] : null, this.$store.state);
    },


    currentPage() {
      return +this.$route.params.pageId;
    },
    numTotalRecs() {
      return this.$store.getters[this.vuexPath + '/totalRecords'];
    },
    numPages() {
      return Math.ceil(this.numRecs / this.page_size);
    },
    recs() {
      return this.$store.getters[this.vuexPath + '/getPageSet'](this.currentPage);
    },


  },
  watch: {
    '$route': function() { this.reqData(); },
    'qry.val': {
      handler: debounce( function() {
            this.gotoPage(1);
        }, 500),
    },
    'qry': {
      handler: debounce( function() {
            this.gotoPage(1);
        }, 500),
    },
  },
  created() {
    console.log('mixin list created');
    this.qry = this.jcopy(this.$store.getters[this.vuexPath + '/getQry']);

    // if (! this.qry ) {
    //   console.log('missing this.qry');
    //   this.qry = {
    //     field: null,
    //     val: null
    //   }
    // } else {
    //   console.log('local qry is ', this.qry);
    // }

    this.reqData();
  },
  methods: {
    jcopy(val) {
      if (val == undefined) {
        return null;
      }

      return JSON.parse(JSON.stringify(val));
    },
    gotoPage (pageId) {
      console.log('goto page q on page', this.qry, pageId);
      this.$store.dispatch(this.vuexPath + '/' + this.vuexPageRequestAction, { page_id: +pageId, qry: this.jcopy(this.qry) } );
      this.$router.push({ params: { pageId }});
    },
    reqData (force) {
      console.log('mixin reqData');
      if (! this.hasPageSet(this.$route.params.pageId) || force) {
        this.$store.dispatch(this.vuexPath + '/' + this.vuexPageRequestAction, { page_id: this.$route.params.pageId, qry: this.jcopy(this.qry), force } );
      }
    },

  },
};

export default storeRecListMixin;
